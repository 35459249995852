import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderContent from "../components/Header/HeaderContent"
import BackgroundHero from "../components/BackgroundHero"
import About from "../components/About"
import LastProject from "../components/LastProject"
import Offer from "../components/Offer"
import Contact from "../components/Contact"

const IndexPage = () => (
  <Layout isHomePage={true}
          footerDesc="Jesteśmy agencją projektowania i rozwoju skoncentrowaną na ludziach z siedzibą w Kielcach, Polska . Zapewniamy doświadczenie w projektowaniu i doradztwo UX , niestandardowe tworzenie oprogramowania oraz dedykowane usługi wsparcia i optymalizacji dla organizacji zorientowanych na klienta ."
          page="home"
  >
    <SEO title="Agencja marketingowa Kielce | CodersBros"
         description="Jesteśmy kompleksową agencją marketingową gotową do przeniesienia Cię na wyższy poziom."
    />
    <HeaderContent/>
    <BackgroundHero img="mainHero" isArrowLink={true}/>
    <About/>
    <Offer/>
    <LastProject/>
    <Contact/>
  </Layout>
)

export default IndexPage
