import React from 'react';
import "./style/offerLink.scss"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { FiChevronsRight } from "react-icons/fi";

function OfferLink(props) {
    return (
        <div className="offerLink">
            <AniLink
              cover to={props.link} bg="#000" dduraction={1} className="offerLink__link" >
                {props.children}
                <div className="link__inner">
                  <h3>{props.text}</h3>
                  {props.desc ? <p className="blank">{props.desc}</p> : ""}
                    <p className="inner__text d-flex aling-items-center">Zobacz więcej <FiChevronsRight/></p>
                </div>
            </AniLink>
        </div>
    );
}

export default OfferLink;