import React, { Component } from "react"
import "../style/headerContent.scss"

class HeaderContent extends Component {
  state = {
    currentLetters: "",
    sentenceCounter: 0,
    isBackTyping: false,
    timeInterval: 15,
    sentences: ["Digital Agency", "Creative Agency", "Software House", "Your Agency", "blank"],
    intervalIndex: 0
  }

  componentDidMount = () => {
    const intervalIndex = setInterval(() => {
      if (!document.hidden) {
        if (!this.state.isBackTyping) {
          this.incrementSentenceCounter()
          this.writeWord(this.state.sentences[this.state.sentenceCounter - 1])
        } else {
          this.deleteWord(this.state.sentences[this.state.sentenceCounter - 1])
        }
      } else {
        return
      }
    }, 2000)

    this.setState({
      intervalIndex: intervalIndex
    })

  }

  incrementSentenceCounter = () => {
    if (this.state.sentenceCounter < this.state.sentences.length - 1) {
      this.setState(
        {
          sentenceCounter: ++this.state.sentenceCounter
        }
      )
    } else {
      this.setState(
        {
          sentenceCounter: 1
        }
      )
    }
  }

  writeWord = (word) => {
    let counter = 0
    const intervalIndex = setInterval(() => {
      counter++
      if (counter <= word.length + 1) {
        this.setState((prevState) => {
          return {
            currentLetters: word.slice(0, counter)
          }
        })
      } else {
        clearInterval(intervalIndex)
      }
    }, this.state.timeInterval)

    this.setState((prevState) => {
      return {
        isBackTyping: !prevState.isBackTyping
      }
    })
  }

  deleteWord = (word) => {
    let counter = word.length
    const intervalIndex = setInterval(() => {
      this.setState((prevState) => {
        --counter
        if (counter >= 0) {
          return {
            currentLetters: word.slice(0, counter)
          }
        } else {
          clearInterval(intervalIndex)
        }
      })
    }, this.state.timeInterval)

    this.setState((prevState) => {
      return {
        isBackTyping: !prevState.isBackTyping
      }
    })
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalIndex)
  }

  render() {
    return (
      <header className="header">
        <div className="header__content">
          <h2 className="header__content-subtitle">
            CodersBros
            <div className="header__content-sign">
              <span className="subtitle__text">
                {this.state.currentLetters}
              </span>
              <span className="subtitle__sign">

              </span>
            </div>
          </h2>
          <h1 className="header__content-title">
            Sklepy i strony internetowe na każdą kieszeń. <br/>
            Zaczynamy tam gdzie zatrzymują się inne agencje.
          </h1>
        </div>
      </header>
    )
  }
}

export default HeaderContent