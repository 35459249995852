import React from 'react';
import SmallTitle from "./smallTitle";
import BigTitle from "./BigTitle";
import "./style/about.scss"

function About(props) {
    return (
        <section id="about" className="about paddingInner">
            <SmallTitle title="o nas"/>
            <BigTitle title="Czy jesteś gotowy na nowoczesność?"/>
            <div className="about__content paddingInside">
                <p>
                    Jesteśmy grupą projektantów i programistów, pasjonującymi się <b>nowoczesnymi technologiami</b> w
                    świecie web developmentu i nie tylko.
                </p>
                <p>
                    Charakteryzujemy się zarówno <b>doświadczeniem</b> jak i <b>innowacyjnością</b>.
                </p>
                <p>
                    Nasz zespół zajmuje się profesjonalnym tworzeniem <strong>stron internetowych,</strong> <strong> sklepów internetowych,</strong>
                    <strong> aplikacji webowych, </strong> <strong>pozycjonowaniem </strong> oraz <strong>testowaniem oprogramowania</strong>.
                </p>
                <p>
                    Do każdego projektu podchodzimy indywidualnie, wykorzystując <b>najnowsze trendy </b>i <b>technologie</b>.
                </p>
            </div>
        </section>
    );
}

export default About;