import React from 'react';
import "./style/projectTitle.scss"

function ProjectTitle(props) {
    return (
        <div className="projectTitle">
            <h4>{props.title}</h4>
        </div>
    );
}

export default ProjectTitle;