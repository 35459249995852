import React from 'react';
import SmallTitle from "./smallTitle";
import BigTitle from "./BigTitle";
import "./style/lastProject.scss"
import Image from "./image";
import ProjectTitle from "./ProjectTitle";
import Button from "./Button";

function LastProject(props) {
    return (
        <section className="lastProject paddingInner">
            <div className="lastProject__container--left">
                <SmallTitle title="ostatnie projekty"/>
                <BigTitle title="Design, prostota, czytelność."/>
                <ProjectTitle title="VitalBox Catering Dietetyczny"/>
                <Image img="lastProjectDesktop"/>
            </div>
            <div className="lastProject__container--right">
                <Image img="lastProjectMobile" />
                <p className="blank">
                    Projekt wykonany dla profesjonalnej firmy cateringowej VitalBox. Naszym celem było połączenie
                    zachęcającego designu z wysoką funkcjonalnością. Pomimo dużej ilości infromacji zachowaliśmy
                    prostotę i czytelność strony co było głównym zalożeniem naszego klienta.
                </p>
                <Button link="https://www.vitalbox.pl/" text="live" isTarget={true}/>
            </div>
        </section>
    );
}

export default LastProject;