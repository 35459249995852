import React, {Component} from "react"
import axios from 'axios';
import "./style/contactForm.scss"


class ContactForm extends Component {

    state = {
        name: "",
        mail: "",
        message: "",
        mailSent: "",
        error: "error"
    }

    handleChange = ({target}) => {
        const changedInputName = target.name

        this.setState({
            [changedInputName]: target.value
        })
    }

    handleClick = (e) => {
        e.preventDefault();

        axios({
            method: 'post',
            url: "index.php",
            headers: {'content-type': 'application/json'},
            data: this.state
        })
            .then(result => {
                console.log(result)
                this.setState({
                    mailSent: "Wiadomość wysłana"
                })
                setTimeout(() => {
                    this.setState({
                        mailSent: ""
                    })
                }, 3000)
            })
            .catch(error => this.setState({error: error.message}));


    }

    render() {
        return (
            <form action="#" method="post" className="form">
                <div className="form__inner">
                    <label htmlFor="name">Imię i nazwisko</label>
                    <input type="text" name="name" id="name" value={this.state.name} onChange={this.handleChange}/>
                </div>
                <div className="form__inner">
                    <label htmlFor="mail">Adres email</label>
                    <input type="mail" name="mail" id="mail" value={this.state.mail} onChange={this.handleChange}/>
                </div>
                <div className="form__inner">
                    <label htmlFor="message">Zostaw wiadomość</label>
                    <textarea name="message" id="message" value={this.state.message}
                              onChange={this.handleChange}></textarea>
                </div>
                <button type="submit" className="form__button" onClick={e => this.handleClick(e)}>
                    Wyślij
                </button>
                {this.state.mailSent ? <p className="form__alert">Wiadomość została wysłana</p> : ""}
            </form>
        )
    }
}

export default ContactForm


