import React from 'react';
import SmallTitle from "./smallTitle";
import BigTitle from "./BigTitle";
import WebsitesIcon from "../assets/svgIcon/websites.svg"
import GraphicIcon from "../assets/svgIcon/graphic.svg"
import SeoIcon from "../assets/svgIcon/seo.svg"
import TestingIcon from "../assets/svgIcon/testing.svg"
import CommerceIcon from "../assets/svgIcon/commerce.svg"
import OfferLink from "./OfferLink";
import Button from "./Button";
import "./style/offer.scss"

function Offer(props) {
    return (
        <section id="oferta" className="offer paddingInner">
            <SmallTitle title="oferta"/>
            <BigTitle title="Szeroka oferta specjalnie dla Ciebie."/>
            <div className="offer__inner">
                    <OfferLink
                      text="Strony Internetowe"
                      link="/strony-internetowe"
                      desc="Projektujemy i rozwijamy costrony internetowe. Naszym celem jest inteligentne połączenie nowoczesnego designu z wysoką funkcjonalnością."
                    >
                        <WebsitesIcon/>
                    </OfferLink>
                    <OfferLink
                      text="Sklepy Internetowe"
                      link="/sklepy-internetowe"
                       desc="Tworzymy i rozwijamy, sklepy internetowe oparte o silnik nopCommerce.">
                        <CommerceIcon/>
                    </OfferLink>
                    <OfferLink text="Testowanie aplikacji i oprogramowania" link="/testowanie" desc="Dostarczamy profesjonalne rozwiązania w zakresie zapewnienia jakości. Świadczymy pełen zakres wsparcia dla usług testowania oprogramowania.">
                        <TestingIcon/>
                    </OfferLink>
                    <OfferLink text="Grafika" link="/grafika" desc="Możemy wykonać dla Państwa: obróbkę cyfrową zdjęć, ulotki, reklamy prasowe, kalendarze, gazetki produktowe, katalogi, wizytówki, papier firmowy, książki, instrukcje, logotypy, ikony, itp.">
                        <GraphicIcon/>
                    </OfferLink>
                    <OfferLink text="Pozycjonowanie" link="/pozycjonowanie-stron" desc="Specjalizujemy się w optymalizacji i dostosowaniu stron oraz sklepów internetowych, aby uzyskać jak najlepszy wynik w wyszukiwarkach.">
                        <SeoIcon/>
                    </OfferLink>
            </div>
        </section>
    );
}

export default Offer;