import React from 'react';
import SmallTitle from "./smallTitle";
import BigTitle from "./BigTitle";
import ContactForm from "./ContactForm";
import "./style/contact.scss"

function Contact(props) {
    return (
        <section id="kontakt" className="contact">
            <div className="contact__inner">
                <div className="inner__left">
                    <SmallTitle title="kontakt"/>
                    <BigTitle title="Skontaktuj się z nami, resztę zrobimy za Ciebie"/>
                    <div className="left__inside">
                        <h4>Telefon</h4>
                        <a href="tel:+48530680028" itemProp="telephone">530 680 028</a>
                    </div>
                    <div className="left__inside">
                        <h4>Email</h4>
                        <a href="mailto:biuro@codersbros.pl" itemProp="email">biuro@codersbros.pl</a>
                    </div>
                    <div className="left__inside">
                        <h4>Lokalizacja</h4>
                        <div itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
                            <span className="location" itemProp="addressLocality"><strong>Kielce, </strong></span>
                            <span className="location" itemProp="addressRegion"><strong>Świętokrzyskie</strong></span>
                        </div>
                    </div>
                </div>
                <div className="inner__right">
                    <ContactForm/>
                </div>
            </div>
        </section>
    );
}

export default Contact;